<template>
  <div>
    <!-- 表格列表 -->
    <div class="listTableTable"  style="position:relative;">
      <el-table :data="list" ref="multipleTable"
       style="'width: 100%;"
      :height="clientHeight"
      v-loading="loading"
      @row-dblclick="handleDalclick"
      @row-click="clickRow"
      @row-contextmenu="rightClick"
      @select="handleSelectionChangeList"
      @sort-change="sortChange"
      :header-cell-class-name="handleHeaderClass"
      :row-class-name="tableRowClassName"
      :cell-class-name="tableCellClassName"
      show-summary
      :summary-method="getSummaries"
      :stripe="true"
      border
      @header-dragend="changeColumnWidth"
      :header-cell-style="{textAlign:'center'}"
      @select-all="tableGetAll"
     >
        <el-table-column type="selection" width="60" fixed="left" align="center">
        </el-table-column>

        <el-table-column type="index" label="序号" width="55" fixed="left" align="-webkit-center">
        </el-table-column>

        <!-- :align="getAlign(item.alignment,item.field_type)" -->
        <!-- :width="item.isSuit?flexColumnWidth(item.field_text,item.field):item.width" :show-overflow-tooltip="!item.isSuit" -->
        <template v-for="(item, index) in rowList">
          <el-table-column  v-if="item.is_use=='1'&&item.field_alias === 'status_name'"
          :align="getAlign(item.alignment,item.field_type)"
          sortable :fixed="item.is_freeze=='1'" show-overflow-tooltip
          :key="index+''+item.field_alias" :prop="item.field_alias" :label="item.field_text" :width="item.width"  >
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.status_name === '审核不通过'" style="color: #fd563a">
                  {{ scope.row.status_name }}
                </div>
                <div v-else-if="scope.row.status_name === '审核通过'" style="color: #21bc36">
                  {{ scope.row.status_name }}
                </div>
                <div v-else>
                  {{ scope.row.status_name }}
                </div>
              </div>
            </template>
          </el-table-column>

          <!--:align="getAlign(item.alignment,item.field_type)" :width="item.isSuit?flexColumnWidth(item.field_text,item.field):item.width" -->
          <el-table-column v-if="item.is_use=='1'&&item.field_alias !== 'status_name'" :key="index+''+item.field_alias" :prop="item.field_alias"
          :align="getAlign(item.alignment,item.field_type)"
          :label="item.field_text" sortable="custom" :fixed="item.is_freeze=='1'"
          show-overflow-tooltip
          :width="item.width">
          </el-table-column>

        </template>
      </el-table>
      <!-- <div class="tableTotal" >合计：{{list.length-1}}行</div> -->
      <!-- 设置 放大 -->
      <div class="setSetion">
        <img class="img1" src="../../../../../assets/images/goods/icon_set.png" alt=""
        @click="dialogTableVisible=true">

        <img @click="fullScreen" class="img2" src="../../../../../assets/images/goods/icon_fullscreen.png"
        alt="">
      </div>
    </div>

    <el-dialog title="设置表头" :visible.sync="dialogTableVisible" :close-on-click-modal ="false">
      <div class="spanBtn">
        <span @click="restore">
          恢复默认
        </span>
        <span @click="allShow('1','show')">
          全部显示
        </span>
        <span @click="allShow('0','show')">
          全部隐藏
        </span>
        <span @click="allShow('1','ice')">
          全部冻结
        </span>
        <span @click="allShow('0','ice')">
          全部解冻
        </span>
        <span @click="moveUp">
          上移
        </span>
        <span @click="moveDown">
          下移
        </span>
      </div>

      <vxe-table height="400" ref="xTable1" border resizable  :data="rowList2"
      @checkbox-all="selectAllEvent" @checkbox-change="selectChangeEvent"
      :edit-config="{trigger: 'click', mode: 'cell',showIcon:false,}" :checkbox-config='{reserve: true }'>

         <vxe-column type="checkbox" width="60"></vxe-column>

         <vxe-column type="seq" width="60" title="序号"></vxe-column>

        <vxe-column v-for="(item,index) in tableColumn" :key="index" :title="item.title"
        :field="item.field" :type="item.type"
        :width="item.width">

            <template #default="{ row }">

              <span v-if="item.type=='text'">{{row.field_text}}</span>

              <vxe-select  v-model="row.alignment"  v-if="item.type=='select1'">
                  <vxe-option  v-for="(item,idx) in options" :key="idx" :value="item.value" :label="item.label">
                  </vxe-option>
              </vxe-select>

              <vxe-input placeholder="最小宽度为20"  @blur="getInputNumber($event,row.field)" v-if="item.type=='input'" v-model="row.width" type="text"></vxe-input>

              <vxe-switch v-if="item.type=='radio1'" v-model="row.is_use" size="medium" open-value="1" close-value="0"></vxe-switch>

              <vxe-switch v-if="item.type=='radio2'" v-model="row.is_freeze" size="medium" open-value="1" close-value="0"></vxe-switch>

          </template>
        </vxe-column>

      </vxe-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="setDispose">
          确 定
        </el-button>
      </span>
    </el-dialog>

  <el-dialog title="选择货品分类" :visible.sync="dialogAdd" :close-on-click-modal ="false">
      <div class="chooseDiv">

        <div style="text-align:center;" @click="setRadio('10')">
          <div class="boxDiv">
            <img src="../../../../../assets/images/goods/icon_one2.png" alt="">
          </div>
          <el-radio style="margin-top:20px;" v-model="isRadio" label="10">
            一码一货
          </el-radio>
        </div>

        <div style="text-align:center;" @click="setRadio('20')">
          <div class="boxDiv">
            <img src="../../../../../assets/images/goods/icon_multiple2.png" alt="">
          </div>
          <el-radio style="margin-top:20px;" v-model="isRadio" label="20">
            一码多货
          </el-radio>
        </div>

      </div>
      <div class="flexDiv">
        <div :class="activeIndex==index?'activeDiv':'noneDiv'" v-for="(item,index) in typeList"
        :key="index" @click="setIndex(index,item.goods_type_id)">
          {{item.goods_type_name}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAdd = false">
          取 消
        </el-button>
        <el-button type="primary" @click="toDetail">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- <choose-type :dialogAdd="dialogAdd"></choose-type> -->

    <!-- 弹框菜单 -->
    <div id='menus' class="menuDiv" v-show="isShowMenu">
        <div :class="index==mouseoverIndex?'mouseActive':''"  v-for="(item,index) in menuList" :key="index" @click="opeater(index)" @mouseover="mouseover(index)">{{item}}</div>
    </div>

    <LocalPrint
        :show="false"
        :printRows="printRows"
        :printData="printData"
        :title="title"
        :dialogVisible="localPrintVisible"
        @onClose="localPrintVisible = false"
      />

    <PrintConf ref="printConf" :type="2" function_code="entryExport" :isShowBtn="false" @onRowList="handlePrintRow" />

    <el-dialog
      :visible.sync="printVisible"
      :before-close="beforeClose"
      title="打印标签"
      :destroy-on-close="true">
      <Print :type="50" :order_id="rowClickList[0]" v-if="printVisible" />
    </el-dialog>

  </div>
</template>

<script>
import { tableConfReq,tableConfSet, restoreReq, handleDefaultReq } from "@/components/tableConf/index.js";
import {
  configList,
  configSave,
  configRestore,
  getList,
  dispose,
  setDispose,
  restoreV2,
  batchUpdateState,
  batchDeleteReverseEntry,
  exportList,
  getEntryPrintReq,
  reverseEntryBill,
} from "@/api/goods/goodsWarehousing/warehousing";
import Sortable from 'sortablejs';
import { getListReq } from "@/api/goods/goodsConfig/classify";
import LocalPrint from "@/components/localPrint/Index.vue";
import PrintConf from "@/components/printConf/Index.vue";
import Print from "@/components/print/Index.vue";
export default{
    props:['condData'],
    components:{LocalPrint,PrintConf,Print},
    data(){
        return{
             isShowType:false,//选择类型
             isALlFit:true,
             printRows: [],
             printData: {},
             currRow: {},
             title: "入库单",
             localPrintVisible:false, //打印
             printVisible:false,      //打印标签
             clientHeight:300,
             activeIndex:0,
             isRadio:'10',
             dialogTableVisible:false, //表格设置
             dialogAdd:false,//选择货品分类
             multipleSelection: [],
             row:{
                sex:'',
                value:true,
             },
             cond: {
                page: 1,
                page_num: 50,
                sort_field:'',//排序字段
                sort_type:'',//1.降序,2.升序

            },
            options: [
                {
                  value: 'left',
                  label: '靠左对齐'
                },
                {
                  value:'right',
                  label:'靠右对齐'
                },
                {
                  value:'center',
                  label:'居中对齐',
                },
                {
                  value:'default',
                  label:'默认'
                }
              ],
              spliceIndex:[], //角标数组
              spliceArr:[],   //上下移动的数组
              tableColumn: [
                {title: '列名', field: 'field_text',type:'text'},
                {title:'宽度',field:'width',type:'input'},
                {title:'对齐方式',field:'alignment',type:'select1',},
                {title:'显示列',field:'is_use',type:'radio1'},
                {title:'冻结列',field:'is_freeze',type:'radio2'},
              ],

               //搜索配置
               loading:false,
               total:'',
               list:[],//数据列表
               rowList:[], //表头
               rowList2:[],//拷贝
               rowClickList:[], //被点击的数组
               typeList:[],//选择类型列表
               goods_type_id:'',

               orderArray:[],
               cond: {
                  page: 1,
                  page_num: 50,
               },
               menuList:[
                '复制',
                // '列宽自适应',
                // '全列宽自适应',
             ],
             isShowMenu:false,   //是否显示菜单
             currentIndex:'-1',//表格点击的横角标
             columnIndex:'', //记录竖列角标
             mouseoverIndex:'',  //菜单角标
             columnObj:{},
             fullscreen:false,
        }
    },
    created(){
        this.clientHeight=document.documentElement.clientHeight-320;
        this.cond = this.condData;//接收父组件传递的分页参数
        this.getList()
        this.getGoodsTypeOneList()
        this.dispose()
    },
    //  watch: {
    //   $route() {
    //     this.$nextTick(() => {
    //       //重载表格
    //       if (this.$refs.multipleTable && this.$refs.multipleTable.doLayout) {
    //         this.$refs.multipleTable.doLayout();
    //       }
    //     })
    //   }
    // },
    updated () {
      this.$nextTick(() => {
        this.$refs['multipleTable'].doLayout();
      })
    },
     mounted() {

      // 阻止默认行为
      // document.body.ondrop = function(event) {
      //   event.preventDefault()
      //   event.stopPropagation()
      // }

       window.addEventListener('click',this.handleClick)
       this.$nextTick(() => {
          //重载表格
          if (this.$refs.multipleTable && this.$refs.multipleTable.doLayout) {
            this.$refs.multipleTable.doLayout();
          }
        })
      const _that=this;
      window.onresize = function () {
        _that.clientHeight=document.documentElement.clientHeight-320;
      };
      this.$bus.$on('updateList', function(value) {
        _that.getList()
      })
        this.columnDrop()

    },
    methods:{//表格子组件接收父组件调用方法，1新增,2删除,3确认,4打印,5导出,6刷新
      childMethod(type,cond){
        console.log(type)
        if(type==1){
          this.dialogAdd=true
        }else if(type==2){
          this.deleteDate();
        }else if(type==3){ //审核
          this.batchUpdateState()
        }else if(type==4){
          this.printList()
        }else if(type==5){
          this.exportList()
        }else if(type==6){
          this.reflush();
        }else if(type==7){//更新查询列表
          this.cond=cond;
          this.loading=true;
          this.getList()
        }else if(type==8){ //打印设置
           this.$refs.printConf.tableVisiable=true
        }else if(type==9){  //打印标签
          if(this.rowClickList.length==0){
              this.$message({
                  type:'warning',
                  message:'请选中打印的数据'
              })
              return;
          }else if(this.rowClickList.length>1){
            this.$message({
                type:'warning',
                message:'暂不支持多条打印'
            })
            return;
          }
           this.printVisible=true
        }else if(type==10){ //反审核
           this.reverseEntryBill()
        }
      },//接收父组件前进后退分页操作
      childHandleCurrentChange(page,page_num){
        this.cond.page=page;
        this.cond.page_num=page_num;
        this.loading=true
        this.dispose()
        this.getList()
      },
      //接收父组件页码配置数据
      pageData(cond){
        console.log(cond)
        this.cond=cond;
      },

      getInputNumber(val,row){
        let idx;
        this.rowList2.some((item,index)=>{
            idx=index
            return row==item.field;
        })
        if(val.value<20){
            this.rowList2[idx].width=20
        }
      },

        setRadio(type){
          this.isRadio=type
        },

        //列拖动
        columnDrop() {
            this.$nextTick(()=>{
                const wrapperTr = document.querySelector('.el-table__header-wrapper tr');
                this.sortableObj = Sortable.create(wrapperTr, {
                  animation: 180,
                  delay: 0,
                  //handle: '.move', // 只有带move类名的元素才能拖动,多选框禁止拖动
                  onEnd: evt => {
                      const oldItem = this.rowList[evt.oldIndex-2]
                      this.rowList.splice(evt.oldIndex-2, 1)
                      this.rowList.splice(evt.newIndex-2, 0, oldItem)
                      if(evt.newIndex>2){
                          let field=this.rowList[evt.newIndex-2].field;  //拖动的
                          let field1=this.rowList[evt.newIndex-3].field;  //拖动的前一个
                          let index=this.rowList2.findIndex((item) => item.field== field)
                          let index1=this.rowList2.findIndex((item) => item.field== field1)
                          let item= this.rowList2.splice(index,1)
                          this.rowList2.splice(index1+1,0,item[0])
                      }else if(evt.newIndex==2){
                          let field=this.rowList[evt.newIndex-2].field;  //拖动的
                          let field1=this.rowList[evt.newIndex-2].field;  //拖动的后一个
                          let index=this.rowList2.findIndex((item) => item.field== field)
                          let index1=this.rowList2.findIndex((item) => item.field== field1)
                          let item= this.rowList2.splice(index,1)
                          this.rowList2.unshift(item[0])
                      }
                  }
                })
            })
        },


       //反审核
        reverseEntryBill(){
            if(this.rowClickList.length==0){
              this.$message({
                  type:'warning',
                  message:'请选中反审核的数据'
              })
              return;
            }
            reverseEntryBill({
              entry_ids:JSON.stringify(this.rowClickList)
            }).then(res=>{
              if(res.code==1){
                  this.rowClickList=[]
                  this.getList()
                  this.$message({
                    type:'success',
                    message:'反审核成功'
                })
              }
            })
        },


        // 合计
        getSummaries({ columns, data }) {
          let that=this;
          const sums = [];
          columns.forEach((column,index)=>{
            if(index==0){
              sums[index]='合计'
              return
            }
            const values = Array.from(data).map(item => {
                return Number(item[column.property])//property为表头的prop值
            });
            if(index>=2){
              if(that.rowList[index-2].is_summary=='1'){
                 sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);//Number转换为数值
                      let sum =Number(Number(prev) + Number(curr))//.toFixed(2);//toFixed(2)数据项保留两位小数
                      if(that.rowList[index]){
                        sum=(parseFloat(sum * 100) / 100).toFixed(parseFloat(that.rowList[index-2].decimal));
                      }
                      if (!isNaN(value)) {
                          return sum;
                      } else {
                          return prev;
                      }
                }, 0);
                  sums[index];
              }
            }
          })
          return sums
        },


      // 全屏事件
      fullScreen() {
        let element = document.documentElement;
        // 判断是否已经是全屏
        if (this.fullscreen) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          // 否则，进入全屏
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            // IE11
            element.msRequestFullscreen();
          }
        }
        this.fullscreen = !this.fullscreen;
      },

       //数据列表 获取全选
        tableGetAll(selection){
          if(selection.length>0){
              selection.forEach((item,index)=>{
                this.rowClickList.push(item.entry_id)
              })
          }else{
            this.rowClickList=[]
          }
        },

       //菜单操作
        opeater(){
          if(this.mouseoverIndex==0){ //复制
            let copyText=this.list[this.currentIndex][this.columnObj.property]
            console.log(copyText)
            this.$copyText(copyText).then(message => {
                  //  this.$message({
                  //     message: '复制成功',
                  //     type: 'success'
                  //   });
              }).catch(err => {
            })
          }
          // else if(this.mouseoverIndex==1){//粘贴
          //   JSON.parse(this.copyList).forEach((item,index)=>{
          //      this.list.push(item)
          //   })
          // }
          else if(this.mouseoverIndex==1){ //单列宽自适应
            let idx;
            this.rowList.some((item,index)=>{
              idx=index
              return this.columnObj.label==item.field_text
            })
            this.$set(this.rowList[idx],'isSuit',true)
          }else if(this.mouseoverIndex==2){//全列宽自适应
              this.rowList.map((item,index)=>{
                item.isSuit=true
              })
              this.$forceUpdate()
          }
        },

        //设置角标
        tableRowClassName({ row,column, rowIndex,columnIndex}) {
            row.index = rowIndex;
        },

         tableCellClassName({ column,columnIndex}) {
            column.index = columnIndex;
        },

        //悬浮变色
        mouseover(idx){
            this.mouseoverIndex=idx
        },

          //自适应列宽
        flexColumnWidth(label, prop){
          // 1.获取该列的所有数据
          const arr = this.list.map(x => x[prop])
          arr.push(label) // 把每列的表头也加进去算
          // console.log(arr)
          // 2.计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
          return (this.getMaxLength(arr) + 50) + 'px'

        },

         getMaxLength (arr) {
          return arr.reduce((acc, item) => {
          if (item) {
            const calcLen = this.getTextWidth(item)
            if (acc < calcLen) {
              acc = calcLen
            }
          }
            return acc
          }, 0)
        },

        getTextWidth(str){
          let width = 0
          const html = document.createElement('span')
          html.innerText = str
          html.className = 'getTextWidth'
          document.querySelector('body').appendChild(html)
          width = document.querySelector('.getTextWidth').offsetWidth
          document.querySelector('.getTextWidth').remove()
          return width
        },

        //鼠标右键弹窗
        rightClick(row,column){
            this.currentIndex=row.index
            this.columnIndex=column.index
            console.log(column)
            this.columnObj=column
            console.log(this.currentIndex)
            event.preventDefault();
            let menu = document.querySelector("#menus");
            menu.style.left =( event.clientX-92) + "px";
            menu.style.top =( event.clientY-85)  + "px";
            // 改变自定义菜单的隐藏与显示
            // menu.style.display = "block";
            menu.style.zIndex = 1000;
            this.isShowMenu=true
        },

        //监听鼠标点击事件
        handleClick(){
            //关闭菜单
            if(this.isShowMenu){
                this.isShowMenu=false
            }
        },

       beforeClose() {
          this.printVisible = false;
       },

      //打印配置
      handlePrintRow(rows) {
        this.printRows = rows;
      },

      isShowPrint(){
        this.localPrintVisible=true
      },

      //打印
      printList(){
          if(this.rowClickList.length==0){
            this.$message({
                type:'warning',
                message:'请选中打印的数据'
            })
          }else if(this.rowClickList.length>1){
            this.$message({
                type:'warning',
                message:'暂不支持多条打印'
            })
          }
          else{
              this.localPrintVisible=true
              getEntryPrintReq({
                entry_id: this.rowClickList[0]
              }) .then((res) => {
              console.log(res);
              if (res.code === 1) {
                this.currRow.select = "";
                res.data.list.forEach((item, index) => {
                  item.index = index + 1;
                });
                res.data.top = [];
                const data = res.data.data;
                const arr1 = [
                  { txt: "创建人", value: data.create_realname, width: "24%" },
                  { txt: "创建时间", value: data.create_time, width: "24%" },
                  { txt: "入库单号", value: data.entry_number, width: "30%" },
                ];
                const arr2 = [
                  { txt: "门店", value: data.short_name, width: "24%" },
                  { txt: "供应商", value: data.supplier_name, width: "24%" },
                  { txt: "仓库", value: data.warehouse_name, width: "24%" },
                  { txt: "品类", value: data.goods_type_name, width: "24%" },
                ];
                res.data.bottom = [];
                // const arr3 = [{ txt: "备注", value: data.note, width: "100%" }];
                const arr4 = [
                  { txt: "审核人", value: data.audit_realname, width: "24%" },
                  {
                    txt: "审核时间",
                    value: data.audit_time ? data.audit_time : "",
                    width: "30%",
                  },
                ];
                res.data.top.push(arr1, arr2);
                res.data.bottom.push(arr4);//arr3,
                // 计算合计
                this.printData = res.data;
                console.log(this.printData)
                const sum = {
                  index: "合计",
                };
                const decimal = {};
                res.data.sum.index="合计";
                this.printData.list.push(res.data.sum);

              }
            })
            .catch(() => {
              this.currRow.select = "";
            });
          }
      },

      //导出列表
      exportList(){
          exportList({
              function_code:'entry',
              entry_ids:JSON.stringify(this.rowClickList),
             })
              .then((res) => {
              const blob = new Blob([res], { type: "application/vnd.ms-excel" });
              const time = new Date().getTime();
              const a = document.createElement("a");
              a.setAttribute("download", `入库单列表.xls`);
              a.href = URL.createObjectURL(blob);
              a.click();
          })
          .catch(() => {
          });
      },

      //审核
      batchUpdateState(){
          if(this.rowClickList.length<=0){
            this.$message({
                message: '请先选择审核的数据',
                type: 'warning'
            });
            return;
          }
          batchUpdateState({
            entry_ids:JSON.stringify(this.rowClickList)
          }).then(res=>{
              if(res.code==1){
                console.log(res)
                this.$message({
                  message: res.msg,
                  type: 'success'
                });
                this.rowClickList=[]
                this.getList()
            }
          })
      },

      setIndex(idx,goods_type_id){
        this.activeIndex=idx
        this.goods_type_id=goods_type_id
      },

      //排序方式
      getAlign(align,field_type){
        if(field_type=='string'&&align=='default'){
          return 'left'
        }else if(field_type=='num'&&align=='default'){
          return 'right'
        }else{
          return align
        }
      },

      //初始化
      restore(){
        this.$confirm('确定恢复默认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
           configRestore({
              fun:'entry',
              mode:'column',
            }).then(res=>{
              if(res.code==1){
                this.rowList2=res.data
                this.spliceIndex=[]
                this.$message({
                    message: '恢复默认成功',
                    type: 'success'
                });
              }
            })
        }).catch({});
      },

       //保存表格配置
      setDispose(){
        let list=JSON.parse(JSON.stringify(this.rowList2));
        for(let i=0;i<list.length;i++){
          list[i].order=(1000-i).toString();
          list[i].option=[];
        }
        configSave({
          list,
          fun:'entry',
          mode:'column'
        }).then(res=>{
          if(res.code==1){
            this.dialogTableVisible=false
              this.rowList=this.rowList2.filter((item,index)=>{
                  return item.is_use==1
              })
            // this.rowList=JSON.parse(JSON.stringify(this.rowList2))
            this.$nextTick(()=>{
              this.columnDrop()
            })
            // this.columnDrop()
            this.$message({
              message: '保存成功',
              type: 'success'
            });
          }
        })
      },

      //全部显示/隐藏
      allShow(type,from){
        if(from=='show'){
            this.rowList2.forEach((item,index)=>{
              item.is_use=type
            })
        }else{
            this.rowList2.forEach((item,index)=>{
              item.is_freeze=type
            })
        }
      },

       //获取表格配置
      dispose(){
        configList({
          fun:'entry',
          is_all:1,
          mode:'column'
        }).then(res=>{
          let arr=[]
          if(res.code==1){
             res.data.forEach((item,index)=>{
              if(item.is_use=='1'){
               this.rowList.push(item)
              }
            })
            // this.rowList=JSON.parse(JSON.stringify(arr))
            //拷贝
            this.rowList2=res.data
          }
        })
      },

      // 排序
      sortChange( {column, prop, order} ){
          console.log(prop)
          //取消order是null
          this.cond.sort_field=column.property
          this.cond.sort_type=order=='ascending'?2:1
          this.getList()
      },

      handleHeaderClass({row, column, rowIndex, columnIndex}){
          this.orderArray.forEach(element => {
            if (column.property===element.prop) {
              column.order=element.order
            }
          });
      },

      //添加
      addOrder(){
        this.dialogAdd=true
      },

       //多选框change事件
       handleSelectionChangeList(selection,row){
            let index=this.rowClickList.indexOf(row.entry_id)
            if(index==-1){
                this.rowClickList.push(row.entry_id)
            }else{
                this.rowClickList.splice(index,1)
            }
        },

        //获取品类下拉数据
        getGoodsTypeOneList() {
          getListReq({ type: [10] }).then((res) => {
            if (res.code === 1) {
              this.typeList = res.data
              // this.changeGoodsType(res.data);
            }
          });
        },

        //删除数据
        deleteDate(){
            if(this.rowClickList.length==0){
                this.$message({
                    type:'warning',
                    message:'请先选中需要删除的数据'
                })
            }else{
                  //删除逻辑
                  this.$confirm('确定删除选中的数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    batchDeleteReverseEntry({
                      entry_ids:JSON.stringify(this.rowClickList)
                    }).then(res=>{
                      if(res.code==1){
                      this.$message({
                        type: 'success',
                        message: '删除成功!'
                      });
                        this.rowClickList=[]
                        this.getList()
                      }
                    })

                  }).catch({});
            }
        },

        //点击选中复选框
        clickRow(row,column,event){
            this.$refs.multipleTable.toggleRowSelection(row);
            let index=this.rowClickList.indexOf(row.entry_id)
            if(index==-1){
                this.rowClickList.push(row.entry_id)
            }else{
                this.rowClickList.splice(index,1)
            }
        },

        //刷新表格
        reflush(){
            this.dispose()
            this.getList()
            this.rowClickList=[]
            this.loading=true
        },

        //表格双击
        handleDalclick(row){
            console.log(row)
            this.$router.push({
              path: "/order/offlineOrder/selesDetail/"+row.entry_id,
              query: {
                  metaTitle: '销售详情',
                  istype:'detail',
                  entry_id:row.entry_id,
                  goods_number_type:row.goods_number_type,
                  goods_type_id:row.goods_type_id,
                  t:Date.now(),
                }
            });
        },

        //获取表格列表
        getList(){
             getList({ ...this.cond })
            .then((res) => {
            if (res.code === 1) {
                this.loading = false;
                res.data.list.map((item) => {
                for (var key in item) {
                    if (!item[key]) {
                    item[key] = "--";
                    }
                }
                  return item;
                });
                this.list = res.data.list;
                this.list.forEach((item,index)=>{
                    item.isSuit=false
                })
                this.$emit('getPaginationData',res.data.total)
            }
            })
            .catch((res) => {
            this.loading = false;
            });
        },

         //表格列宽变化
        changeColumnWidth(newWidth,oldWidth,column){
           this.sortableObj.destroy()
           let idx;
           this.rowList2.some((item,index)=>{
              idx=index
              return column.property==item.field_alias
            })
            //省略号问题
            // if(newWidth<oldWidth){
            //   this.$set(this.rowList[idx],'isSuit',false)
            // }
            this.rowList2[idx].width=newWidth
            this.rowList[idx].width=newWidth
            this.columnDrop()
        },

        //新增页面
        toDetail(){
            this.dialogAdd=false
            this.$router.push({
              path: "/order/offlineOrder/selesDetail/"+Date.now(),
              query: {
                metaTitle: '销售详情',
                goods_number_type:this.isRadio,
                goods_type_id:this.goods_type_id?this.goods_type_id:this.typeList[0].goods_type_id,
                istype:'add',
                t:Date.now(),
              }
          });
        },

        //向上移动
        moveUp(){
          let min=Math.min(...this.spliceIndex);
          if(min===0){
            return false;
          }
          this.spliceIndex=this.spliceIndex.sort((a, b) => a - b);
          let list=JSON.parse(JSON.stringify(this.rowList2));
          for(let i=0;i<this.spliceIndex.length;i++){
            [list[this.spliceIndex[i]],list[this.spliceIndex[i]-1]]=[list[this.spliceIndex[i]-1],list[this.spliceIndex[i]]]
            this.spliceIndex[i]-=1
          }
          this.rowList2=[...list];
          let that=this;
          setTimeout(function(){//延时调用组件传递方法
              that.$refs.xTable1.scrollToRow(list[that.spliceIndex[0]]);
          },200);
        },

        //向下移动
        moveDown(){
          let max=Math.max(...this.spliceIndex);
          if(max===this.rowList2.length-1){
            return false
          }
          this.spliceIndex=this.spliceIndex.sort((a, b) => a - b)
          this.spliceIndex=this.spliceIndex.reverse()
          let list=JSON.parse(JSON.stringify(this.rowList2));
          for(let i=0;i<this.spliceIndex.length;i++){
            [list[this.spliceIndex[i]],list[this.spliceIndex[i]+1]]=[list[this.spliceIndex[i]+1],list[this.spliceIndex[i]]]
            this.spliceIndex[i]+=1
          }
          this.rowList2=[...list];
          let that=this;
          setTimeout(function(){//延时调用组件传递方法
              that.$refs.xTable1.scrollToRow(list[that.spliceIndex[0]]);
          },200);
        },

        selectAllEvent({checked}){
            const records = this.$refs.xTable1.getCheckboxRecords()
            if(checked){
                records.forEach((item,index)=>{
                  this.spliceIndex.push(index)
                })
                this.spliceArr=records
            }else{
              this.spliceIndex=[]
              this.spliceArr=[]
            }
        },

        selectChangeEvent({ row,rowIndex,checked }){
            if(checked){
                this.spliceIndex.push(rowIndex)
                this.spliceArr.push(row)
            }else{
                let index= this.spliceIndex.indexOf(rowIndex)
                this.spliceIndex.splice(index,1)
                this.spliceArr.splice(index,1)
            }
        },

    }
}
</script>
<style lang="less" >
  .listTableTable{
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
      background: #FAFAFA !important;
    }
    .el-table {//解决合计缩放丢失
          overflow: visible !important;
      }
      .el-table__fixed-footer-wrapper tbody td.el-table__cell{
        background: #FFF8E1;
      }
    .has-gutter{
      .el-table__cell{
        background: #FFF8E1;
        border: 0;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #595959;
      }


    }
    .el-table{
      tr{
        height: 35px !important;
      }
      .el-table__row{
         height: 40px !important;
      }

      th{
        height: 35px !important;
        padding: 0 !important;
      }
      .el-table__header-wrapper{
         height: 35px !important;
      }
    }
  }
</style>
<style lang='less' scoped>
.tableTotal{
  width: 99.3%;
  height: 20px;
  background: #FFF8E1;
  box-shadow: 0px -1px 0px 0px #FFECD1, 0px 1px 0px 0px #E5E5E5;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  bottom: 10px;
  z-index: 99;
  padding: 10px 8px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #6A7386;
}

.spanBtn{
    display: flex;
    justify-content:flex-end;
    span{
        cursor: pointer;
        display: inline-block;
        padding:0 10px;
        margin:10px 0;
        color: #007BFF;
        border-right: 1px solid #E6E6E6;
    }
}
/deep/ .el-table .caret-wrapper{
  width: 0 !important;
}
// 表头样式
/deep/ .el-table th{
    background-color: rgba(242,242,242,1) !important;
    color: #595959 !important;
}
/deep/ .el-table th.el-table__cell>.cell{
  // border-right: 1px solid rgba(216,216,216,1);
}
/deep/ .vxe-body--column{
    line-height: 48px;
}
/deep/ .row--hover{
    background: #D4E9FD !important;
}

.headerBox{
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
}

.setSetion{
   position:absolute;top:0px;right:1px;z-index:2;height:35px;
   padding: 0 10px;
   background: #fafafa;
   box-shadow: -4px 0px 10px rgba(0,0,0,0.2);
   .img1{
    width:16px;height:16px;cursor:pointer;
    margin-top: 8px;
   }
   .img2{
    width:16px;height:16px;margin-left:10px;cursor:pointer;
    margin-top: 8px;
   }
}
.headerBox_title{
    font-size: 16px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #595959;
}

.chooseDiv{
    display: flex;justify-content:center;
    .boxDiv{border:1px dashed #F1F1F1; margin:0 140px;
        img{width:140px;height: 120px;}
    }
}
.flexDiv{
     display: flex;margin-top:40px;flex-wrap:wrap;padding:0 10px;
    .commonDiv{
      width: 110px;height: 32px;background: #F1F1F1;border-radius: 4px;text-align: center;line-height: 32px;
      margin: 15px 15px 0 0;cursor: pointer;font-size: 12px;border: 1px solid #F1F1F1;
    }
    .noneDiv{
      .commonDiv;
      color: #595959;
    }
    .activeDiv:extend(.noneDiv){
      .commonDiv;
      color: #007BFF;border: 1px solid #007BFF;
    }
}

 //表格自适应
   .el-table /deep/ th {
    padding: 0;
    white-space: nowrap;
    min-width: fit-content;
  }

  .el-table /deep/ td {
    padding: 1px;
    white-space: nowrap;
    width: fit-content;
  }

  /** 修改el-card默认paddingL:20px-内边距 **/
 .el-card__body {
    padding: 10px;
  }

  .el-table /deep/ .cell {
    white-space: nowrap;
    width: fit-content;
  }

  .menuDiv{
    position: absolute;
    display: inline-block;
    line-height: 25px;
    letter-spacing: .5px;
    padding:10px 15px;
    width: 130px;
    color: #595959;
    background: #fff;
    border: 1px solid rgba(230,230,230,1);
    .mouseActive{
        color: #007BFF;
        cursor: pointer;
        background: rgba(233,243,253,1);
    }
}
</style>
