<template>
  <el-dialog title="高级搜索" :visible.sync="dialogTableVisible" width='774px' class="Screening_dialog" :closeOnClickModal='false' >
      <div class="Screening_cont">
          <div class="Screening_ul" v-if="list.length>0">
            <Drag  :list="list" :hideArr="hideArr" :newSetList="newSetList"  :config="config1" ref="Dragchild" @setList="setList">
            </Drag>
          </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="resetLeft" @click="reset(1)">
          重置
        </el-button>
        <el-button class="resetLeft" @click="seveSetConfig()">
          保存配置
        </el-button>
        <el-button @click="dialogTableVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="confirm(2)" style="margin-right:20px">
          确 定
        </el-button>
      </div>
  </el-dialog>
</template>

<script>
import Drag from "./drag.vue";
import { configuration,configRestore} from "@/api/components/index.js";
export default {
  props:['searchData'],//接收父组件参数
  components: {
    Drag
  },
  data() {
    return {
      dialogTableVisible:false,//弹层控制开关
      list:[],//筛选项配置数据
      newSetList:[],
      config1: {
        name: "test",
        push: true,
        pull: true,
        exchange: true,
      },
      hideArr:[],
    };
  },
  created() {
    this.configuration(this.searchData);
  },
  methods: {//打开高级筛选
    setScreening(state,cond){
      let that=this;
      this.dialogTableVisible=state;//打开高级筛选弹窗
      setTimeout(function(){//延时调用组件传递方法
        that.$refs.Dragchild.childMethod(0,JSON.parse(JSON.stringify(cond)));
      },"500");


    },//获取高级筛选各类选择器的值和类型
    configuration(searchData){
      configuration(searchData).then((res) => {
        if (res.code === 1) {
          let setList={};
          for(let i=0;i<res.data.length;i++){
            if(res.data[i].as_field){
              if(!setList[res.data[i].as_field]){
                  setList[res.data[i].as_field]=[]
              }
              res.data[i].sindex=i;
              setList[res.data[i].as_field].push(res.data[i])
            }
          }
          console.log(setList)
          this.newSetList=setList;
          // this.list=res.data;
          res.data.forEach((item,index)=>{
              if(item.is_auth!='0'){
                this.list.push(item)
              }else{
                this.hideArr.push(item)
              }
          })
        }
      })
    },//恢复默认搜索配置
    restore(){
      configRestore({fun:'Entry',mode:'search'}).then((res) => {
          if(res.code==1){
            this.$message({
              message: '恢复默认成功',
              type: 'success'
            });
            console.log(res.data)
            // this.list=res.data;
            res.data.forEach((item,index)=>{
              if(item.is_auth!='0'){
                this.list.push(item)
              }else{
                this.hideArr.push(item)
              }
            })
          }
        })
    },
    setList(list){
      this.list=list;

    },
    //保存配置
    seveSetConfig(){
      this.$refs.Dragchild.childMethod(3,'',this.searchData);
    },
    //重置搜素
    reset(type){
       this.$refs.Dragchild.childMethod(type);
    },//确认查询
    confirm(type){
      this.dialogTableVisible = false;
      this.$refs.Dragchild.childMethod(type,'',this.searchData);
    }

  }
};
</script>
<style lang='less'>
  .Screening_dialog{
    .el-dialog__body{
      padding: 0 32px;
    }
    .el-dialog__footer{
      padding: 0;
    }
    .list-item{
      .queryCondition{
        margin-bottom: 0px;
        .el-input .el-input__inner {
          font-size: 14px;
          height: 32px;
          line-height: 32px;
        }
      }
    }
  }
</style>
<style lang='less'  scoped>
.dialog-footer{
  border-top: 1px solid rgba(0, 0, 0, 0.0600);
  padding: 10px;
}

.resetLeft{
  float: left;
  margin-left: 20px;
}
.Screening_cont{
  height: 428px;
  margin: 20px 0;
  overflow: auto;
}
.dragBox {
  display: flex;
  justify-content: center;
}
.spanBtn{
    display: flex;
    justify-content:flex-end;
    span{
        cursor: pointer;
        display: inline-block;
        padding:0 10px;
        margin:10px 0;
        color: #007BFF;
    }
  }
</style>
