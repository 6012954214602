<template>
  <el-form name="group-list" :model="formQuery"  label-width="90px"  label-position="left">
    <div
      v-for="(item, index) in list"
      :key="item.name"
      :draggable="true"
      :class="[
        'list-item',
        {
          'is-dragover':
            index === dropIndex && config.exchange,
        },
      ]"
      @dragstart="onDragstart($event, index)"
      @dragenter="onDragenter(index)"
      @dragover.prevent="onDragover(index)"
      @dragleave="onDragleave"
      @dragend="onDragend"
      @drop="onDrop"
    >
    <!-- 文本输入框:string 时间段选择:date 下拉框多选择:array 下拉框单选：radio 固定下拉框:fixed 动态搜索：search number_range：范围选择-->
     <el-form-item :label="item.field_text" class="queryCondition" >
        <span v-if="item.box_type=='string'" class="string">
          <el-input class="queryCondition_input"  :placeholder="item.field_text" v-model="formQuery[item.field]"  @keydown.enter.native="onSubmit"></el-input>
        </span>
        <!-- number_range：范围选择  -->
        <span v-if="item.box_type=='number_range'"  class="string number_range">
          <el-input class="queryCondition_input"  :placeholder="item.field_text" v-model="formQuery[item.field][0]"  type="text" @input="allNumber(formQuery[item.field][0],item.field,0)"></el-input>
          <span class="number_rangeSpan">-</span>
          <el-input class="queryCondition_input"  :placeholder="item.field_text" v-model="formQuery[item.field][1]"  type="text"  @input="allNumber(formQuery[item.field][1],item.field,1)"></el-input>
        </span>
        <!-- date时间段选择-->
        <span v-if="item.box_type=='date'" class="datePicker">
          <el-date-picker
            v-model="formQuery[item.field]"
              type="daterange"
              align="left"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
          </el-date-picker>
        </span>

      <!-- 下拉框单选：radio  -->
        <span v-if="item.box_type=='radio'">
          <el-select v-model="formQuery[item.field]" :placeholder="item.field_text" filterable clearable >
            <el-option
              v-for="childItem in item.option"
              :key="childItem.key"
              :label="childItem.value"
              :value="childItem.key">
            </el-option>
          </el-select>
        </span>
        <!-- 下拉框多选择:array -->
        <span v-if="item.box_type=='array'||item.box_type=='array_no_option'">
          <el-select v-if="!setList[item.field]" v-model="formQuery[item.field]" :placeholder="item.field_text" filterable   multiple collapse-tags >
            <el-option
              v-for="childItem in item.option"
              :key="childItem.key"
              :label="childItem.value"
              :value="childItem.key">
            </el-option>
          </el-select>
          <el-select  v-else  @change="(val)=>{ associatedFun(val,item)}" v-model="formQuery[item.field]"  :placeholder="item.field_text" filterable   multiple collapse-tags >
            <el-option
              v-for="childItem in item.option"
              :key="childItem.key"
              :label="childItem.value"
              :value="childItem.key">
            </el-option>
          </el-select>

        </span>
        <!-- 下拉框远程搜索:search -->

        <span v-if="item.box_type=='search'">
          <el-select v-model="formQuery[item.field]" :placeholder="item.field_text"  filterable   multiple collapse-tags    remote reserve-keyword :loading="loading" :remote-method="(query) => getProductList(query, item,index)">
            <template>
            <el-option
              v-for="childItem in item.option"
              :key="childItem.key"
              :label="childItem.value"
              :value="childItem.key">
            </el-option>
            </template>
          </el-select>
        </span>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import {remoteSet,setConfig,associatedSet} from "@/api/components/index.js";

import moment from 'moment';
export default {
  name: "Draggable",
  inject: ["seniorScreening"],//需要调用的祖先的方法
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    hideArr:{
      type:Array,
      default:[],
    },
    newSetList: {
      type: Object,
    },
    config: {
      type: Object,
      default: () => ({
        name: "",
        push: true,
        pull: true,
        exchange: true,
      }),
    },
  },

  data() {
    return {
      dragIndex: null,
      dropIndex: null,
      formQuery:{},//选项绑定提交值集合
      loading:false,
      setList:{},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨日',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getDay(), start.getDate(), 'start')
              var thisDay = start.getDay() - 1;
              var thisDate = start.getDate();
              if (thisDay != 0) {
                start.setDate(thisDate - thisDay);
              }
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上周',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          },
             {
            text: '本月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上个月',
            onClick(picker) {
              const oDate = new Date();
              var year = oDate.getFullYear();
              var month = oDate.getMonth();
              var start, end;
              if (month == 0) {
                year--
                start = new Date(year, 11, 1)
                end = new Date(year, 11, 31)
              } else {
                start = new Date(year, month - 1, 1)
                end = new Date(year, month, 0);
              }

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '今年',
            onClick(picker) {
              let end = new Date();
              let start = moment().startOf('year').format('YYYY-MM-DD');
              start=new Date(start);

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '本季度',
            onClick(picker) {
              let start = moment().startOf('quarter').format('YYYY-MM-DD');
              let end=moment().endOf('quarter').format('YYYY-MM-DD');
              start=new Date(start);
              end=new Date(end);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上季度',
            onClick(picker) {
              let start = moment().quarter(moment().quarter() - 1).startOf('quarter').format('YYYY-MM-DD')
              let end=moment().quarter(moment().quarter() - 1).endOf('quarter').format('YYYY-MM-DD')
              start=new Date(start);
              end=new Date(end);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
    };
  },

  computed: {
    isPush() {
      const { dropIndex, dragIndex } = this;
      return dropIndex !== null && dragIndex === null;
    },

    isExchange() {
      const { dropIndex, dragIndex } = this;
      return dragIndex !== null && dropIndex !== null;
    },

    pushCbName() {
      const {
        config: { name },
      } = this;
      return `${name}-push-callback`;
    },
  },
  created() {
    this.setList=this.newSetList;
    this.setFormQuery();
  },
  methods: {
    //下拉关联数据查询
    associatedFun(val,item,type){
      console.log(val)
      let  list=this.setList[item.field];
      if(list){
        for(let i=0;i<list.length;i++){
          let data={};
          data[list[i].as_field]=val;

          this.associatedSet(data,list[i].select_code,list[i].sindex,type)
        }
      }
    },//下拉清空值
    associatedSet(data,select_code,sindex,type){
      associatedSet(data,select_code).then((res) => {
        console.log(res)
        if(res.code==1){
          this.list[sindex].option=res.data;
          if(type!=1){//等于1清空下拉值
            this.$set(this.formQuery,this.list[sindex].field,'');
          }else{
            this.$set(this.formQuery,this.list[sindex].field,this.formQuery[this.list[sindex].field]);
          }

        }
      })
    },
    allNumber(e,name,index) {
      let value = e.replace(/[^\-\d.]/g, '') // 只能输入.和-和数字
      value = value.replace(/^\./g, '')  //第一个字符不能是.
      value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
      value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
      value = value.replace(/(-)\./g, '$1') // -后面不能输入.
      value = value.replace(/\-{2,}/g, '-') // -只能保留一个
      value = value.replace(/(\d+|\.)-/g, '$1') // 数字和.后面不能接-,不能出现类似11-, 12.-
      value = value.replace(/-(0){2,}/g, "$1") // 不能出现-00,-001,-0001类似
      value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
      value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
      value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
      this.formQuery[name][index]=value;
    },
    //接收父组件传递的参数
    childMethod(type,cond,configuration){
      let that=this;
      if (type==0){
        this.formQuery=cond;
        this.setFormQuery();
      }else if(type==1){//重置
        this.formQuery={};
        this.setFormQuery();
      }else if(type==2){//高级筛选确认操作
        this.seniorScreening(1,this.formQuery)

      }else if(type==3){//保存搜索配置
        let list=JSON.parse(JSON.stringify(this.list));
        for(let i=0;i<list.length;i++){
          list[i].order=(1000-i).toString();

          list[i].option=[];
        }
        setConfig({fun:configuration.fun,mode:'search',list:[...list,...this.hideArr]}).then((res) => {
          if(res.code==1){
            this.$message({
              message: '保存配置成功',
              type: 'success'
            });
            this.$emit('setList',res.data);
          }
        })
      }else if(type==4){//保存搜索配置
        this.setFormQuery();
      }

    },
    //远程搜素
    getProductList(query,res,index){
      if (query !== '') {
        this.loading = true;
        remoteSet({select_code:res.select_code,search:query}).then((res) => {
          console.log(res)
          if (res.code === 1) {
             this.loading=false;
           this.list[index].option=res.data
          }
        })
      } else {
          this.loading=false;
          this.list[index].option = [];
      }
      console.log(this.list)
    },
    setFormQuery(){
      //键值为字符串时才能绑定
      console.log(this.list)
      for(let i=0;i<this.list.length;i++){
        //范围选择
        if(this.list[i].box_type=='number_range'){
          this.$set(this.formQuery,this.list[i].field,[]);
        }

      }
      this.formQuery=this.formQuery;
    },
    onDragstart(e, i) {
      const {
        list,
        config: { name },
        transferData,
      } = this;

      this.dragIndex = i;

      if (name) {
        transferData({ e, key: name, type: "set", data: list[i] });
      } else {
        throw new Error("缺少配置关联名name");
      }

      this.$emit("drag-start", i);
    },

    onDragenter(i) {
      this.dropIndex = i;
      this.$emit("drag-enter", i);
    },

    onDragover(i) {
      const { dragIndex, dropIndex } = this;
      if (i === dragIndex || i === dropIndex) return;
      this.dropIndex = i;
      this.$emit("drag-over", i);
    },

    onDragleave() {
      this.dropIndex = null;
    },

    onDrop(e) {
      const {
        list,
        dropIndex,
        dragIndex,
        config: { name, push: enablePush, exchange },
        isPush,
        isExchange,
        pushCbName,
        storage,
        resetIndex,
        transferData,
      } = this;

      if (dropIndex === dragIndex || !exchange) return;

      if (isPush) {
        if (!enablePush) {
          resetIndex();
          return;
        }

        if (name) {
          list.splice(
            dropIndex,
            0,
            transferData({ e, key: name, type: "get" })
          );

          storage("set", pushCbName, true);
        } else {
          resetIndex();
          throw new Error("缺少配置关联属性name");
        }
        resetIndex();
        return;
      }

      if (isExchange) {
        const drapItem = list[dragIndex];
        const dropItem = list[dropIndex];
        list.splice(dropIndex, 1, drapItem);
        list.splice(dragIndex, 1, dropItem);
      }

      resetIndex();
    },

    onDragend() {
      const {
        list,
        dragIndex,
        config: { pull: enablePull },
        pushCbName,
        storage,
        resetIndex,
      } = this;

      if (enablePull) {
        const isPushSuccess = storage("get", pushCbName);

        if (isPushSuccess) {
          list.splice(dragIndex, 1);
          storage("remove", pushCbName);
        }
      }
      resetIndex();
      this.$emit("drag-end");
    },
    storage(type, key, value) {
      return {
        get() {
          return JSON.parse(localStorage.getItem(key));
        },
        set() {
          localStorage.setItem(key, JSON.stringify(value));
        },
        remove() {
          localStorage.removeItem(key);
        },
      }[type]();
    },

    resetIndex() {
      this.dropIndex = null;
      this.dragIndex = null;
    },

    transferData({ e, key, type, data } = {}) {
      if (type === "get") {
        return JSON.parse(e.dataTransfer.getData(`${key}-drag-key`));
      }

      if (type === "set") {
        e.dataTransfer.setData(`${key}-drag-key`, JSON.stringify(data));
      }
    },
  },
};
</script>

<style lang="less" >
  .datePicker{
    height: 40px;
    .el-input__inner {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0;
      width:220px;
    }
    .el-icon-date{
      margin-left: 6px;
    }
    .el-range-input{

    }

  }

</style>
<style  scoped lang="less" >
.list-item {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  border-radius: 4px;
  background-color: #fff;
  cursor: move;
  width: 50%;
}
.queryCondition{
  .string{
    width: 220px;
    display: inline-block;
    height: 32px;
    border-radius: 4px;
  }

}
.number_range{
  .queryCondition_input{
        width: 95px;
  }
  .number_rangeSpan{
    display: inline-block;
    margin: 0 10px;
  }
}
</style>
