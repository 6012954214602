<template>
  <div class="datePicker">
    <el-date-picker
      v-model="value"
        type="daterange"
        align="left"
        unlink-panels
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="setPicker"
        @blur="setPicker"
        :picker-options="pickerOptions">
    </el-date-picker>
  </div>
</template>

<script>
import storage from "good-storage";
import Cookies from "js-cookie";
import moment from 'moment';
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨日',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getDay(), start.getDate(), 'start')
              var thisDay = start.getDay() - 1;
              var thisDate = start.getDate();
              if (thisDay != 0) {
                start.setDate(thisDate - thisDay);
              }
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上周',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          },
             {
            text: '本月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上个月',
            onClick(picker) {
              const oDate = new Date();
              var year = oDate.getFullYear();
              var month = oDate.getMonth();
              var start, end;
              if (month == 0) {
                year--
                start = new Date(year, 11, 1)
                end = new Date(year, 11, 31)
              } else {
                start = new Date(year, month - 1, 1)
                end = new Date(year, month, 0);
              }

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '今年',
            onClick(picker) {
              let end = new Date();
              let start = moment().startOf('year').format('YYYY-MM-DD');
              start=new Date(start);

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '本季度',
            onClick(picker) {
              let start = moment().startOf('quarter').format('YYYY-MM-DD');
              let end=moment().endOf('quarter').format('YYYY-MM-DD');
              start=new Date(start);
              end=new Date(end);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上季度',
            onClick(picker) {
              let start = moment().quarter(moment().quarter() - 1).startOf('quarter').format('YYYY-MM-DD')
              let end=moment().quarter(moment().quarter() - 1).endOf('quarter').format('YYYY-MM-DD')
              start=new Date(start);
              end=new Date(end);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      value: [],
    };
  },

  inject: ["setDatePicker"],//需要调用的父组件的方法
  created() {

  },
  methods:{//子组件传递值
    setPicker(){
      this.setDatePicker(this.value)
    },//接受父组件传值
    setData(data){
      this.value=data;
    }
  }
};
</script>
<style lang="less" >
  .datePicker{
    height: 40px;
    .el-input__inner {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0;
      width:220px;
    }
    .el-icon-date{
      margin-left: 6px;
    }
    .el-range-input{

    }
  }

</style>
